import { ComponentProps, FC, Fragment } from 'react';
import { useCountdown } from 'helper/countdown';
import { useSuspenseQuery } from '@tanstack/react-query';
import axios from 'axios';
import { baseUrl } from 'api/terraport/base';
import { FormattedDollar } from 'ui/components/formatted-number';
import { filter, map, slice, sortBy } from 'lodash';
import clsx from 'clsx';
import { BsCopy } from 'react-icons/bs';
import { copyToClipboard } from 'helper/utils';
import { Link } from 'react-router-dom';
import { Tooltip } from 'react-tooltip';
import { format } from 'date-fns';

type Position = {
  pair_address: string;
  token_address: string;
  token_logo_URI: string;
  token_name: string;
  token_symbol: string;
  total_volume_usd: number;
  weekly_volumes: {
    date: string;
    volume_usd: number;
  }[];
};

const VertBar = ({
  position,
  index,
  ...props
}: ComponentProps<'div'> & { position: Position; index: number }) => (
  <div className="my-2 flex w-12 flex-col items-stretch justify-end rounded-t-md md:w-32" {...props}>
    {map(position.weekly_volumes, ({ date, volume_usd }, i) => (
      <Fragment key={date}>
        <div
          id={`${position.token_address}-${i}`}
          className={clsx('z-[1] bg-current', 'first:rounded-t-md')}
          style={{
            opacity: (i + 1) / position.weekly_volumes.length,
            height: `${(100 * volume_usd) / position.total_volume_usd}%`,
          }}></div>
        <Tooltip
          place="left"
          disableStyleInjection
          anchorSelect={`#${position.token_address}-${i}`}
          className={clsx(
            'z-[3] flex flex-col items-start p-2',
            index === 1 && '!bg-[#FFBF00]',
            index === 2 && '!bg-[#DBDFE6]',
            index === 3 && '!bg-[#CE8946]',
          )}>
          <p className="font-semibold text-black">Week of {format(new Date(date), 'MM/dd')}</p>
          <FormattedDollar className="text-lg font-semibold text-black" value={volume_usd} />
        </Tooltip>
      </Fragment>
    ))}
    {/* <div className="absolute flex h-full w-full rounded-t-md bg-current opacity-30" />
    <div className="absolute bottom-0 h-[85%] w-full bg-current opacity-30" />
    <div className="absolute bottom-0 h-[60%] w-full bg-current opacity-30" />
    <div className="absolute bottom-0 h-[15%] w-full bg-current opacity-30" /> */}
  </div>
);

const HorizBar = ({ position, className, ...props }: ComponentProps<'div'> & { position: Position }) => (
  <div className={clsx('flex h-8 flex-row items-stretch justify-start', className)} {...props}>
    {map(position.weekly_volumes, ({ date, volume_usd }, i) => (
      <Fragment key={date}>
        <div
          id={`${position.token_address}-${i}`}
          className={clsx('z-[1] bg-current', 'last:rounded-r-md')}
          style={{
            opacity: 1 - i / position.weekly_volumes.length,
            width: `${(100 * volume_usd) / position.total_volume_usd}%`,
          }}></div>
        <Tooltip
          place="top"
          disableStyleInjection
          anchorSelect={`#${position.token_address}-${i}`}
          className="elevate-8 z-[3] flex flex-col items-start !bg-accent-btn p-2">
          <p className="font-semibold text-black">Week of {format(new Date(date), 'MM/dd')}</p>
          <FormattedDollar className="text-lg font-semibold text-black" value={volume_usd} />
        </Tooltip>
      </Fragment>
    ))}
    {/* <div className="absolute flex h-full w-full rounded-r-md bg-current opacity-30" />
    <div className="absolute left-0 h-full w-[85%] bg-current opacity-30" />
    <div className="absolute left-0 h-full w-[60%] bg-current opacity-30" />
    <div className="absolute left-0 h-full w-[15%] bg-current opacity-30" /> */}
  </div>
);

const Cup = (props: ComponentProps<'svg'>) => (
  <svg
    className="size-6 md:size-12"
    viewBox="0 0 24 24"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    {...props}>
    <path
      d="M11.25 18.25H9C7.9 18.25 7 19.15 7 20.25V20.5H6C5.59 20.5 5.25 20.84 5.25 21.25C5.25 21.66 5.59 22 6 22H18C18.41 22 18.75 21.66 18.75 21.25C18.75 20.84 18.41 20.5 18 20.5H17V20.25C17 19.15 16.1 18.25 15 18.25H12.75V15.96C12.5 15.99 12.25 16 12 16C11.75 16 11.5 15.99 11.25 15.96V18.25Z"
      fill="currentColor"
    />
    <path
      d="M18.48 11.64C19.14 11.39 19.72 10.98 20.18 10.52C21.11 9.49 21.72 8.26 21.72 6.82C21.72 5.38 20.59 4.25 19.15 4.25H18.59C17.94 2.92 16.58 2 15 2H9.00003C7.42003 2 6.06003 2.92 5.41003 4.25H4.85003C3.41003 4.25 2.28003 5.38 2.28003 6.82C2.28003 8.26 2.89003 9.49 3.82003 10.52C4.28003 10.98 4.86003 11.39 5.52003 11.64C6.56003 14.2 9.06003 16 12 16C14.94 16 17.44 14.2 18.48 11.64ZM14.84 8.45L14.22 9.21C14.12 9.32 14.05 9.54 14.06 9.69L14.12 10.67C14.16 11.27 13.73 11.58 13.17 11.36L12.26 11C12.12 10.95 11.88 10.95 11.74 11L10.83 11.36C10.27 11.58 9.84003 11.27 9.88003 10.67L9.94003 9.69C9.95003 9.54 9.88003 9.32 9.78003 9.21L9.16003 8.45C8.77003 7.99 8.94003 7.48 9.52003 7.33L10.47 7.09C10.62 7.05 10.8 6.91 10.88 6.78L11.41 5.96C11.74 5.45 12.26 5.45 12.59 5.96L13.12 6.78C13.2 6.91 13.38 7.05 13.53 7.09L14.48 7.33C15.06 7.48 15.23 7.99 14.84 8.45Z"
      fill="currentColor"
    />
  </svg>
);

const endDate = new Date('2024-12-01T00:00:00.000Z');
const startDate = new Date('2024-11-01T00:00:00.000Z');
export const CompetitionPage: FC = () => {
  const started = Date.now() > startDate.getTime();
  const cd = useCountdown(started ? endDate : startDate);
  const { data } = useSuspenseQuery({
    queryKey: ['memecomp'],
    queryFn: async () => {
      const res = (await axios.get<Position[]>(`${baseUrl}/meme-token-war/volumes`)).data;
      return sortBy(
        filter(
          map(res, (t) =>
            t.pair_address === 'terra12j3xuxx52cg045qk37ee4k4u4fsgvyuf8d89dh7c9mr706jvxdascahqej'
              ? {
                  ...t,
                  pair_address: 'terra1u5tl9hf3q5uhtch5xan5rkf0w9awzn0klwffsphupykafm52n6jqr3ns8y',
                  token_address: 'terra12j3xuxx52cg045qk37ee4k4u4fsgvyuf8d89dh7c9mr706jvxdascahqej',
                  token_logo_URI: 'https://i.ibb.co/2MWVvZC/Go-Art-1715889934142.jpg',
                  token_name: 'Rottweiler',
                  token_symbol: 'ROTTI',
                }
              : t,
          ),
          (t) => !!t.token_address,
        ),
        (t) => -t.total_volume_usd,
      );
    },
  });
  const [first, second, third, ...rest] = data;
  return (
    <Fragment>
      <section className="flex w-full max-w-[500px] flex-col items-center gap-2 px-2 py-8 text-center text-white">
        <p className="text-xl font-semibold tracking-widest text-subtitle">MEME TOKEN COMPETITION</p>
        <p className="text-3xl font-semibold tracking-wider text-accent-btn">LEADERBOARD</p>
        <p>The leaderboard is beeing updated every 3H!</p>
        <p className="pt-2 text-xl font-semibold tracking-wider text-accent-btn">
          {started ? 'TIME REMAINING' : 'COMPETITION STARTS IN'}
        </p>
        <div className="flex w-full flex-row items-center gap-2 font-semibold">
          <div className="flex w-1/4 flex-col rounded-lg bg-accent-btn p-2 text-black">
            <span className="text-lg font-bold md:text-2xl">{cd[0]}</span>
            <span className="text-sm font-semibold md:text-base">days</span>
          </div>
          <span className="text-2xl">:</span>
          <div className="flex w-1/4 flex-col rounded-lg bg-accent-btn p-2 text-black">
            <span className="text-lg font-bold md:text-2xl">{cd[1]}</span>
            <span className="text-sm font-semibold md:text-base">hours</span>
          </div>
          <span className="text-2xl">:</span>
          <div className="flex w-1/4 flex-col rounded-lg bg-accent-btn p-2 text-black">
            <span className="text-lg font-bold md:text-2xl">{cd[2]}</span>
            <span className="text-sm font-semibold md:text-base">min</span>
          </div>
          <span className="text-2xl">:</span>
          <div className="flex w-1/4 flex-col rounded-lg bg-accent-btn p-2 text-black">
            <span className="text-lg font-bold md:text-2xl">{cd[3]}</span>
            <span className="text-sm font-semibold md:text-base">sec</span>
          </div>
        </div>
      </section>
      <div className="flex flex-row items-end justify-evenly gap-2 md:gap-8">
        <div className="flex flex-col items-center text-center text-[#DBDFE6]">
          <Cup />
          <p className="text-lg font-semibold">2nd</p>
          <p className="text-center text-xs text-white">Total Volume</p>
          <FormattedDollar value={second.total_volume_usd} className="font-semibold text-white md:text-lg" />
          <VertBar
            index={2}
            position={second}
            style={{ height: `${(second.total_volume_usd / first.total_volume_usd) * 300}px` }}
          />
          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <img src={second.token_logo_URI} className="size-10 rounded-full object-contain md:size-9" />
            <div className="hidden w-28 flex-col items-start md:flex">
              <p className="font-logo">{second.token_symbol}</p>
              <Link
                className="flex flex-row items-center gap-1 text-xs text-white underline"
                to={`/trade/${second.pair_address}`}>
                {slice(second.token_address, 0, 6)}...{slice(second.token_address, -5)}
                <BsCopy
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(second.token_address);
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center text-center text-[#FFBF00]">
          <Cup />
          <p className="text-lg font-semibold">1st</p>
          <p className="text-center text-xs text-white">Total Volume</p>
          <FormattedDollar value={first.total_volume_usd} className="font-semibold text-white md:text-lg" />
          <VertBar
            index={1}
            position={first}
            style={{ height: first.total_volume_usd === 0 ? '0' : '300px' }}
          />
          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <img src={first.token_logo_URI} className="size-10 rounded-full object-contain md:size-9" />
            <div className="hidden w-28 flex-col items-start md:flex">
              <p className="font-logo">{first.token_symbol}</p>
              <Link
                className="flex flex-row items-center gap-1 text-xs text-white underline"
                to={`/trade/${first.pair_address}`}>
                {slice(first.token_address, 0, 6)}...{slice(first.token_address, -5)}
                <BsCopy
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(first.token_address);
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
        <div className="flex flex-col items-center text-center text-[#CE8946]">
          <Cup />
          <p className="text-lg font-semibold">3rd</p>
          <p className="text-center text-xs text-white">Total Volume</p>
          <FormattedDollar value={third.total_volume_usd} className="font-semibold text-white md:text-lg" />
          <VertBar
            index={3}
            position={third}
            style={{ height: `${(third.total_volume_usd / first.total_volume_usd) * 300}px` }}
          />
          <div className="flex flex-row items-center justify-center gap-2 text-white">
            <img src={third.token_logo_URI} className="size-10 rounded-full object-contain md:size-9" />
            <div className="hidden w-28 flex-col items-start md:flex">
              <p className="font-logo">{third.token_symbol}</p>
              <Link
                className="flex flex-row items-center gap-1 text-xs text-white underline"
                to={`/trade/${third.pair_address}`}>
                {slice(third.token_address, 0, 6)}...{slice(third.token_address, -5)}
                <BsCopy
                  className="cursor-pointer"
                  onClick={(e) => {
                    e.stopPropagation();
                    copyToClipboard(third.token_address);
                  }}
                />
              </Link>
            </div>
          </div>
        </div>
      </div>
      <section className="grid grid-cols-1 items-end gap-2 px-4 py-12 text-white md:grid-cols-[auto_1fr] md:gap-4">
        <div />
        <p className="text-lg font-semibold uppercase tracking-wide text-subtitle">
          Classification of all the Meme Tokens
        </p>
        {map(rest, (token) => (
          <Fragment key={token.pair_address}>
            <div className="hidden flex-row items-center justify-end gap-2 md:flex">
              <div className="flex w-28 flex-col items-end">
                <p className="font-logo">{token.token_symbol}</p>
                <Link
                  className="flex flex-row items-center gap-1 text-xs text-white underline"
                  to={`/trade/${token.pair_address}`}>
                  {slice(token.token_address, 0, 6)}...{slice(token.token_address, -5)}
                  <BsCopy
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(token.token_address);
                    }}
                  />
                </Link>
              </div>
              <img src={token.token_logo_URI} className="size-9 rounded-full object-contain" />
            </div>
            <div className="flex flex-row items-center justify-start gap-2 pt-2 md:hidden">
              <img src={token.token_logo_URI} className="size-8 rounded-full object-contain" />
              <div className="flex w-28 flex-col items-start">
                <p className="font-logo">{token.token_symbol}</p>
                <Link
                  className="flex flex-row items-center gap-1 text-xs text-white underline"
                  to={`/trade/${token.pair_address}`}>
                  {slice(token.token_address, 0, 6)}...{slice(token.token_address, -5)}
                  <BsCopy
                    className="cursor-pointer"
                    onClick={(e) => {
                      e.stopPropagation();
                      copyToClipboard(token.token_address);
                    }}
                  />
                </Link>
              </div>
            </div>
            <div className="flex flex-col items-start gap-1">
              <p className="flex flex-row gap-1 text-lg font-semibold">
                Total Vol <FormattedDollar value={token.total_volume_usd} />
              </p>
              <HorizBar
                position={token}
                style={{ width: `${(token.total_volume_usd / rest[0].total_volume_usd) * 100}%` }}
                className="text-accent-btn"
              />
            </div>
          </Fragment>
        ))}
      </section>
    </Fragment>
  );
};

export default CompetitionPage;
